






import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import BaseMixin from '@/misc/BaseMixin.mixins';

@Component
export default class DashboardView extends mixins(BaseMixin) {

}
